import { render, staticRenderFns } from "./platformLandingPage.vue?vue&type=template&id=7ed82de7&scoped=true&"
import script from "./platformLandingPage.vue?vue&type=script&lang=js&"
export * from "./platformLandingPage.vue?vue&type=script&lang=js&"
import style0 from "./platformLandingPage.vue?vue&type=style&index=0&id=7ed82de7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed82de7",
  null
  
)

export default component.exports